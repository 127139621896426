<template>
  <v-container>
    <v-row>
      <v-img
        :src="require('@/assets/logomarca.png')"
        max-height="10vh"
        max-width="10vh"
      ></v-img>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            color="grey darken-2"
            large
            slot="activator"
            v-on="on"
            @click="fechar()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        Voltar
      </v-tooltip>
    </v-row>
    <v-row class="headline blue--text">
      Vamos começar?
    </v-row>
    <v-row class="display-1 primary--text font-weight-medium">
      Aproveite todas as vantagens de um cliente Apetrus!
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    this.$root.$on("close_cad", () => {
      this.fechar();
    });
  },
  methods: {
    fechar() {
      this.$root.$emit("reset_fields");
      window.parent.postMessage("closing_cad", "*");
    }
  }
};
</script>
